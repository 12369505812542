.sendbird-chat-dashboard {
  position: relative;
  display: flex;
  height: 100vh;
  margin: calc(var(--sidebar-padding) * -1);

  --message-self-color: #d7e3b1;
  --message-self-text-color: #102b1f;
  --message-self-meta-text-color: #163e13;
  --message-participant-color: #063406;
  --message-participant-text-color: #f8ffe9;
  --message-participant-meta-text-color: #b8b7aa;

  .error.message {
    position: absolute;
    inset: 10px 10px auto;
  }
}
