.sendbird-unread-badge {
  display: inline-flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: #fff;
  background: #db2828;
  border: 1px solid #ecf6d4;
  border-radius: 50%;
  flex: none;
}
