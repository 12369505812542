.sendbird-conversations {
  --conversations-color: #f5f5f5;
  --conversations-selected-color: #d7e3b1;

  display: flex;
  flex-flow: column;
  position: relative;
  flex: 0 0 max(30%, 320px);
  background: var(--conversations-color);

  &__header {
    padding: 16px;
    border-bottom: 1px solid #dce3dd;

    &-filters {
      display: flex;
      flex-flow: column;
      padding: 10px;
      gap: 10px;
    }
  }

  &__channels {
    padding: 8px;
    overflow-y: auto;
  }

  &--empty {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.1em;
    opacity: 0.7;
  }

  &__avatars {
    display: flex;

    .sendbird-avatar {
      border: 2px solid var(--conversations-selected-color);

      &:not(:first-child) {
        margin-left: -10%;
      }
    }
  }

  &__meta {
    display: flex;
    align-items: center;

    & :not(:first-child) {
      margin-left: -10%;
    }
  }

  &__unread-count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    color: #fff;
    background: #145314;
    border-radius: 80px;
    margin-left: auto;
    flex: none;
    font-weight: bold;
  }

  &__priority {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    color: #fff;
    background: #db2828;
    border: 2px solid #ecf6d4;
    border-radius: 50%;
    box-sizing: content-box;
  }

  &__item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 4px;

    &:hover,
    &--selected {
      background: var(--conversations-selected-color);
    }

    &--empty {
      text-align: center;
      font-size: 1.2em;
    }
  }
}
