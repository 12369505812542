@import 'styles/easing';

.twilio-video {
  position: relative;
  height: 100%;

  video {
    height: 100%;
    border-radius: 4px;
    background-color: #000;
  }

  &__remotes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(40%, 1fr));
    gap: 10px;
    height: 100%;
  }

  &__remote {
    &-media {
      display: contents;

      video {
        width: 100%;
      }
    }

    &-name {
      position: absolute;
      top: 0;
      width: 100%;
      color: #fff;
      font-weight: 500;
      text-align: center;
      padding: 10px;
    }

    &-muted {
      padding: 2px;
      margin-left: 1em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background-color: #eb4b3d;
      vertical-align: text-bottom;

      .icon {
        width: 14px;
        height: 14px;
        margin: 0;
      }
    }
  }

  &__local {
    position: absolute;
    inset: 0;
    transition: inset 0.5s @easeInOutCubic;

    video {
      width: 100%;
    }

    .twilio-video--active & {
      inset: 70% 0 0 auto;

      video {
        width: auto;
      }
    }
  }

  &__controls {
    position: absolute;
    display: flex;
    gap: 8px;
    left: 15px;
    bottom: 15px;
    user-select: none;
  }

  &__control {
    color: #fff;
    background: #282828;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;

    &:hover {
      background-color: #333;
    }

    &--disconnect {
      padding: 12px 28px;
      background-color: #eb4b3d;
    }

    &--disabled {
      background-color: #eb4b3d;
    }

    .icon {
      width: 18px;
      height: 18px;
      margin: 0;
    }
  }
}
