.sendbird-avatar {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;

  &--small {
    width: 24px;
    height: 24px;
  }

  &--blank {
    background: #163e13;
    object-fit: contain;
  }
}
