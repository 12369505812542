.sendbird-channel {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 12px 24px;
  min-height: 50vh;
  flex: auto;

  &--single {
    max-height: 70vh;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dce3dd;
    flex-wrap: wrap;
    gap: 20px;
    padding: 8px 0;
    min-height: 46px;

    &-users {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .sendbird-channel--single & {
      display: none;
    }

    &-user {
      display: flex;
      gap: 6px;
      align-items: center;

      a {
        display: contents;
        color: inherit;
      }
    }

    &-bar {
      width: 1px;
      background-color: #dce3dd;
      align-self: stretch;
    }

    &-action {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      &-icon {
        --color: #555555;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        color: var(--color);
        border: 2px solid var(--color);
        border-radius: 50%;
        box-sizing: content-box;
        font-weight: bold;
        flex: none;

        &--priority {
          --color: #db2828;
          color: #fff;
          background: var(--color);
          border: none;
        }
      }
    }
  }

  &__messages {
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    flex: auto;
    gap: 8px;
    padding-top: 24px;

    .sendbird-channel--single & {
      padding-top: 0;
    }
  }

  &__date {
    align-self: center;
    padding: 4px 16px;
    background: #ecf6d4;
    border: 1px solid rgba(6, 52, 6, 0.14);
    border-radius: 28px;
    margin: 5px 0 10px 0;
  }

  &__message {
    display: flex;
    align-items: end;
    flex-flow: row;
    gap: 8px;
    --border-radius-large: 16px;
    --border-radius-small: 4px;

    &--self {
      --color: var(--sb-message-self-color);
      --text-color: var(--sb-message-self-text-color);
      --meta-text-color: var(--sb-message-self-meta-text-color);
      flex-flow: row-reverse;
    }

    &--participant {
      --color: var(--sb-message-participant-color);
      --text-color: var(--sb-message-participant-text-color);
      --meta-text-color: var(--sb-message-participant-meta-text-color);
    }

    &-content {
      color: var(--text-color);
      background: var(--color);
      border-radius: var(--border-radius-large);
      padding: 12px 16px 4px 16px;

      &--self {
        border-bottom-right-radius: var(--border-radius-small);
      }

      &--participant {
        border-bottom-left-radius: var(--border-radius-small);
      }
    }

    &-meta {
      display: flex;
      justify-content: end;
      gap: 0.3em;
      color: var(--meta-text-color);
      text-align: right;
      font-size: 11px;
      opacity: 0.5;
    }
  }

  &__typing-indicator {
    display: flex;
    gap: 5px;
    padding: 8px 10px;
    display: inline-flex;
    background-color: var(--sb-message-participant-color);
    margin-top: 4px;
    border-radius: 4px;
    align-self: start;

    &-dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #fff;
      animation: blink 1s ease-in-out forwards infinite;
      &:nth-child(1) {
        animation-delay: 0.33s;
      }
      &:nth-child(2) {
        animation-delay: 0.66s;
      }
    }
  }

  &__new-message {
    margin-top: 12px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 8px;

    .ui.form {
      display: flex;
      align-items: center;
      flex: auto;

      .ui.input {
        flex: auto;

        input {
          font-size: 16px !important;
          background: transparent !important;
          border: none !important;
          padding: 6px !important;
        }
      }

      .ui.button {
        width: 32px !important;
        height: 32px !important;
        padding: 0 !important;
        border-radius: 50% !important;
        background: #555555 !important;
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.25;
    transform: scale(1);
  }

  50% {
    opacity: 0.75;
    transform: scale(1.2);
  }

  100% {
    opacity: 0.25;
    transform: scale(1);
  }
}
