.error-message {
  &__details {
    padding-left: 1em;
  }

  &__show-details {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
