@import 'styles/easing';

.meeting-room {
  position: relative;
  height: 60vh;
  color: #fff;
  border-radius: 4px;
  transition: height 200ms @easeInOutCubic;
  background-color: #191919;

  &--docked {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 25vh;
    aspect-ratio: 16 / 9;
  }

  &--sticking {
    position: sticky;
    z-index: 100;
    top: 20px;
    height: 20vh;
  }

  &__lobby {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  &-spacer {
    height: 30vh;
  }
}
