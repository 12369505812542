:not(.ui) {
  &.xxs {
    font-size: 11px;
  }

  &.xs {
    font-size: 12px;
  }

  &.sm {
    font-size: 13px;
  }

  &.md {
    font-size: 14px;
  }

  &.lg {
    font-size: 16px;
  }

  &.xl {
    font-size: 18px;
  }

  &.xxl {
    font-size: 24px;
  }

  &.thin {
    font-weight: 300;
  }

  &.semi {
    font-weight: 500;
  }

  &.bold {
    font-weight: bold;
  }

  &.text-center {
    text-align: center;
  }

  &.text-right {
    text-align: right;
  }

  &.link {
    color: #29729f;
    cursor: pointer;
  }
}
